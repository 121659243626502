import {showGlobalSpinner} from '../../common';

const handleConfirmRemoveCartItemButtonOnClick = (button: HTMLButtonElement, dialog: HTMLElement) => (e: MouseEvent) => {
  $(dialog).modal('hide');
  showGlobalSpinner();
  button.closest('form')?.submit();
};

const removeAllCartItem = (e: MouseEvent) => {
  const target = e.currentTarget as HTMLButtonElement;
  const dialog = document.querySelector<HTMLElement>('#js-remove-cart-item-confirm-dialog');

  const button = dialog.querySelector('.js-confirm-remove-all-cart-item-button');
  const confirmedEventListener = handleConfirmRemoveCartItemButtonOnClick(target, dialog);
  button.addEventListener('click', confirmedEventListener);

  const span = dialog.querySelector<HTMLElement>('.js-remove-all-cart-item-confirm-room-name');
  span.textContent = target.dataset.exhibitionRoomName;
  $(dialog).modal('show');
  $(dialog).on('hidden.bs.modal', () => {
    button.removeEventListener('click', confirmedEventListener);
  });
};

document.addEventListener('DOMContentLoaded', () => {
  document.querySelectorAll<HTMLButtonElement>('.cart.page .js-remove-all-cart-item-button').forEach(button => {
    button.addEventListener('click', e => {
      removeAllCartItem(e);
    });
  });
});
